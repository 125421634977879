html {
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
body {
  font-family: "Sarabun", sans-serif !important;
}

.column2 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
}

.icon-rizz {
  width: 60%;
  height: 60%;
  margin-left: -1vw;
}

.icon-max {
  width: 60%;
  height: 60%;
  margin-top: -2vw;
}
.image-logo {
  width: 4cm;
  height: 3cm;
}




.icon-dplus {
  width: 60%;
  height: 60%;
  margin-top: 0vw;
  margin-left: -1vw;
}

/* .col {
      display: flex !important;
      justify-content: center !important;
      background-color: white;
  } */

.column {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.ant-input {
  /* width: 200px !important; */
  margin: 0 8px 8px 0;
}

.example {
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  width: 100vw;
  height: 100vh;
  padding: 20px;
}

@page body-print {
  size: 8.27in 11.69in;
  margin: 0.5in 0.5in 0.5in 0.5in;
  mso-header-margin: 0.5in;
  mso-footer-margin: 0.5in;
  mso-paper-source: 0;
}

.body-print {
  /* background-color:#FFFFFF;   
      border: solid 1px black ;  
      margin: 3mm */
}

@page {
  size: auto;
  margin: 0mm;
}

/* width */

/*###################### BillShippingScene ######################*/

.container-bill {
  /* height: 100vh; */
  width: 21cm;
  height: 29.6cm;
  padding: 0.2cm;
  page-break-after: always;
  position: relative;
  border: 1px solid #000;
  margin: auto;
}

.image-logo {
  width: 4cm;
  height: 2cm;
}

.content-section1 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: "100%";
  /* text-align: center; */
  margin-top: 2.5cm;
  padding-left: 20%;
  padding-right: 10%;
}

.content-section1 font {
  font-size: 0.5cm;
}

.section1 {
  border-bottom: 1px solid #000;
  padding: 3mm;
  height: 50%;
}
.btn-active {
  margin-left: 12px;
  width: 10vw;
  height: 20vw !important;
  background:  #0783C1 !important;
  color: #FFFFFF !important;
}
.btn-active:disabled{
  background:  #ececec !important;
  color: #7c7c7c !important;
}

.section1 font {
  font-size: 0.4cm;
}

.content-section2 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: "100%";
  /* text-align: center; */
  margin-top: 2cm;
  padding-left: 5%;
  padding-right: 5%;
}

.border-print {
  height: 100%;
}

@media print {
  body {
    width: 21cm;
    height: 0cm;
  }
  .border-print {
    border: 1px solid #000;
    padding: 0mm;
  }
  .section1 {
    border-bottom: 1px solid #000;
    padding: 3mm;
    height: 100%;
  }
  .container-bill {
    /* height: 100vh; */
    width: 20.5cm;
    height: 10cm;
    padding: 0.5cm;
    page-break-after: always;
    position: relative;
    border: 0px solid #000;
    margin: auto;
  }
}

thead[class*="ant-table-thead"] th {
  background-color: #eee !important;
  font-size: 0.8vw;
}
.ant-select-lg .ant-select-selection--single {
  height: 36px !important;
}
.ant-btn {
  /* min-width: 7vw !important; */
  height: 2.4vw !important;
  font-size: 1vw !important;
  /* margin: 3px !important; */
}
.btn-blue {
  background-color: #0783c1 !important;
  border-color: #0783c1 !important;
  color: #fff !important;
}

.ant-input {
  /* height: 2.4vw !important; */
  /* font-size: 1vw !important; */
  height: 36px !important;
  font-size: 15px !important;
}

.even {
  background-color: #fbfbfb !important;
}

.row-between {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.row-between2 {
  -webkit-align-items: flex-start !important;
          align-items: flex-start !important;
}

.td-content {
  font-size: 0.8vw;
  cursor: pointer;
}

.td-content-confirm {
  padding: 0px !important;
  width: 7vw !important;
}

.ant-table-wrapper {
  width: 100%;
  padding: 0 !important;
}

font {
  font-size: 0.8vw;
}

.text-customer {
  font-size: 0.4cm;
}

.table-report tr:hover td {
  background-color: inherit !important;
  opacity: 1;
}

.ant-table-body {
  margin: 0px !important;
}

.header-login {
  color: #fff;
  padding: 1vw;
}

.content-login {
  padding-right: 1vw;
  padding-left: 1vw;
  padding-top: 1.4vw;
  padding-bottom: 1.4vw;
}

.font-table {
  font-size: 80%;
  width: 3vw;
}

/*###################### Registerwarranty ######################*/
.boorder-detail {
  border: 1px solid #000;
}
.border-claim {
  border: 0.5px solid #cccccc;
}
.column-claim-cr {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 20px;
  margin-left: 30px;
}
.input-claim-cr {
  width: 100%;
  margin-top: 20px;
}
.global-search-wrapper {
  padding-right: 50px;
}

.global-search {
  width: 100%;
}

.global-search.ant-select-auto-complete .ant-select-selection--single {
  margin-right: -46px;
}

.global-search.ant-select-auto-complete
  .ant-input-affix-wrapper
  .ant-input:not(:last-child) {
  padding-right: 62px;
}

.global-search.ant-select-auto-complete
  .ant-input-affix-wrapper
  .ant-input-suffix
  button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.global-search-item {
  display: -webkit-flex;
  display: flex;
}

.global-search-item-desc {
  -webkit-flex: auto;
          flex: auto;
  text-overflow: ellipsis;
  overflow: hidden;
}

.global-search-item-count {
  -webkit-flex: none;
          flex: none;
}
.certain-category-icon-uncolect {
  color: #a02b29;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-size: 16px;
}
.certain-category-icon-colect {
  color: #54b458;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-size: 16px;
}
.outline-focus {
  outline-color: #f92525;
}

.row-edit {
  margin-top: 1vw;
}

.text-status {
  font-size: 0.8vw;
  margin-left: 0.8vw;
}

.contain {
  object-fit: contain;
}

.text-area {
  font-size: 0.8vw !important;
  height: 3.4vw !important;
}

.zoom {
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s; /* Animation */
  margin: 0 auto;
}
.zoom:hover { /* IE 9 */
  -webkit-transform: scale(2); /* Safari 3-8 */
  transform: scale(2);
}
.bg-warranty {
  padding: 2em !important;
  border: 0.5px solid #d1d0d0;
  margin: 1em;
}
.col-warranty {
  margin: 6px !important;
}
.input-warranty {
  margin-top: 6px !important;
  margin-bottom: 12px !important;
}
.select-warranty {
  margin-top: 6px !important;
  margin-bottom: 12px !important;
  width: 100%;
}
.label {
  font-size: 14px;
}
.btn-edit {
  font-size: 16px !important;
  padding: 0px 16px 0px 16px;
  background-color: #faad14 !important;
  color: #fff !important;
  border: 1px solid #faad14 !important;
}

.btn-map {
  font-size: 16px !important;
  padding: 0px 16px 0px 16px;
  background-color: #1890ff !important;
  color: #fff !important;
  border: 1px solid #1890ff !important;
}
.ant-switch-checked {
  background-color: #1890ff !important;
}
.btn-upload {
  background-color: #1890ff !important;
  color: #fff !important;
}
.fileInput {
  opacity: 1;
  /* position: absolute; */
  cursor: pointer;
}

.fileImage {
  opacity: 0;
  position: absolute;
  cursor: pointer;
}
.text-title {
  text-align: center;
  margin-top: 1em;
}

.btn-add {
  font-size: 16px !important;
  padding: 0px 16px 0px 16px;
  background-color: #0783c1 !important;
  color: #fff !important;
  border: 1px solid #0783c1 !important;
  margin-bottom: 1em !important;
  float: right;
  z-index: 1;
}
.alert-success {
  margin-top: 8px;
  margin-bottom: 0px;
  color: #52c41a;
  text-align: right;
}
.alert-error {
  margin-top: 8px;
  margin-bottom: 0px;
  color: #e80410;
  text-align: right;
}
.alert-info {
  margin-top: 8px;
  margin-bottom: 0px;
  color: #0783c1;
  text-align: right;
}
.btn-save {
  background-color: rgb(82, 196, 26) !important;
  color: #fff !important;
  border: 1px solid rgb(82, 196, 26) !important;
}
.ant-input-search-button {
  background-color: #0783c1 !important;
  border: 1px solid #0783c1 !important;
}
.btn-search {
  position: absolute !important;
  margin-left: -48px;
  padding: 4px 13px;
  background-color: #0783c1 !important;
  border: 1px solid #0783c1 !important;
  color: #fff !important;
  font-size: 18px;
  border-radius: 0px 4px 4px 0px !important;
  height: 36px !important;
  font-size: 15px !important;
}
.btn-filter {
  height: 36px !important;
  margin-top: 29px !important;
  background-color: #52c41a !important;
  color: #fff !important;
}
textarea.ant-input {
  height: auto !important;
}

::-webkit-scrollbar {
    width: 10px;
    border: 0
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1 !important; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #3782BC !important; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #3782BC !important; 
  }
